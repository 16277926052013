import * as types from './types';
import { settingKey } from '../config/constants';
import { setTenure } from './loan';

export const putSettings = settings => {
	return dispatch => {
		localStorage.setItem(settingKey, JSON.stringify(settings));
		dispatch({
			type: types.PUT_SETTINGS,
			payload: settings,
		});
		dispatch(setTenure(1));
	};
};

export const updateWallet = wallet => {
	console.log(wallet);
	return;
};
